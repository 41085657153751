import { safeFormatDate } from '@common/date/formatters';
import { Label } from '@invest-ai/animatrix';
import { Scrollbar } from '@invest-ai/animatrix';
import { formatPercentage, safeDivide } from '@invest-ai/common';
import { Circle } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import type {
  PositionsImporterHistoric,
  RowProblemDescription,
} from '../../../../__generated__/types';
import { UpdateImportedItemName } from '../UpdateImportedItemName';
import { RowProblem } from './RowProblem';

const IMPORT_DATE_TIME_PATTERN = "dd 'de' MMMM 'de' yyyy, HH'h'mm'm'";
const REFERENCE_DATE_TIME_PATTERN = "dd 'de' MMMM 'de' yyyy";

interface ImportedItemProps {
  data: PositionsImporterHistoric;
}

const RowProblemsList: React.FC<{
  problems: RowProblemDescription[];
}> = ({ problems }) => {
  return (
    <>
      <Typography color='error' gutterBottom variant='caption'>
        Esta planilha apresentou os seguintes erros durante a importação:
      </Typography>
      <Scrollbar sx={{ maxHeight: 400, mt: 1 }}>
        {problems.map((rowProblem) => (
          <RowProblem
            key={`${rowProblem.columnName}_${rowProblem.line}`}
            rowProblem={rowProblem}
          />
        ))}
      </Scrollbar>
    </>
  );
};

const SuccessMessage: React.FC = () => (
  <Typography color='primary' variant='caption'>
    Esta planilha foi importada com 100% de sucesso!
  </Typography>
);

export const ImportedItem: React.FC<ImportedItemProps> = ({ data }) => {
  const importedDateFormatted = safeFormatDate(
    new Date(data.date),
    IMPORT_DATE_TIME_PATTERN,
  );
  const referenceDateFormatted = safeFormatDate(
    new Date(data.referenceDate),
    REFERENCE_DATE_TIME_PATTERN,
  );
  const errorsCount = data.parsingErrors.length;
  const ignoredCount = data.parsingIgnored.length;
  const successCount = data.successCount;

  const totalCount = errorsCount + successCount;
  const errorsPercentage = safeDivide(errorsCount, totalCount);
  const successPercentage = safeDivide(successCount, totalCount);
  const advisorsCount = 2;
  const fixedIncomePositionsCount = 3;
  const brokerAccountsCount = 3;

  const name = data?.name ?? importedDateFormatted;

  return (
    <Accordion
      TransitionProps={{
        unmountOnExit: true,
      }}
    >
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Box flex={1} flexDirection='row' display='flex' alignItems='center'>
          <Box marginRight={2} display='flex' alignItems='center'>
            <Circle color='primary' />
          </Box>
          <Box flex={1}>
            <Box display='flex' alignItems={'center'}>
              <Typography variant='subtitle2'>{name}</Typography>
              <UpdateImportedItemName itemId={data.id} currentName={name} />
            </Box>
            {data?.referenceDate && (
              <Typography fontSize={12} color='#637381' mb={2}>
                Data de referência das posições: {referenceDateFormatted}
              </Typography>
            )}
            {totalCount !== 0 && (
              <Stack direction='row' spacing={1} mb={1}>
                <Label color='info'>Total linhas: {totalCount}</Label>
                <Label color='success'>
                  Sucesso: {successCount} ({formatPercentage(successPercentage)}
                  )
                </Label>
                <Label color='error'>
                  Erros: {errorsCount} ({formatPercentage(errorsPercentage)})
                </Label>
              </Stack>
            )}

            {/* <Stack direction="row" spacing={1}>
              <Label color="primary">
                Posições: {fixedIncomePositionsCount}
              </Label>
              <Label color="primary">Assessores: {advisorsCount}</Label>
              <Label color="primary">Clientes: {brokerAccountsCount}</Label>
            </Stack> */}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box paddingX={2}>
          {data.parsingErrors.length ? (
            <RowProblemsList problems={data.parsingErrors} />
          ) : (
            <SuccessMessage />
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
