import { Indexers } from '@common/enum/Indexers';
import { formatReaisOrUndefined } from '@common/logic/formatReaisOrUndefined';
import { DisplayError } from '@invest-ai/animatrix';
import { Grid, Typography } from '@mui/material';
import { valueOrLoader } from '../../DealPitch/valueOrLoader';
import { InformationBlock } from '../../InformationBlock';
import { useDealFixedIncomePositionComponentData } from '../hooks/useComponentData';
import { MaybeNil } from '../logic/MaybeNil';
import { formatDateOrUndefined } from '../logic/formatDateOrUndefined';
import { getTooltipYearlyYieldRate } from '../logic/getTooltipYearlyYieldRate';
import { RebuyUnitPriceInformationBlock } from './RebuyUnitPriceInformationBlock';

export interface IDealFixedIncomePositionProps {
  dealId: string;
}

export const DealFixedIncomePosition: React.FC<{
  positionId: string;
  offerId: MaybeNil<string>;
}> = ({ positionId, offerId }) => {
  const {
    shouldDisplayCustomerMarkupOnOperationDate,
    customerMarkupOnOperationDateColor,
    durationString,
    customerMarkupOnOperationDateReais,
    minorDuration,
    updatedAtText,
    fixedIncomePositionYieldDescription,
    loadingDealFinancials,
    loadingFixedIncomePosition,
    fixedIncomePosition,
    dealFinancials,
    shouldDisplayProjectedPrice,
    shouldDisplayAccumulativeDividends,
    error,
  } = useDealFixedIncomePositionComponentData({ positionId, offerId });

  if (error) {
    return <DisplayError error={error} />;
  }

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Ativo a ser vendido</Typography>
        <Typography fontSize={12} marginBottom={3} color={'#919EAB'}>
          Data de Referência: {updatedAtText}
        </Typography>
        <Typography
          variant='subtitle1'
          color='#637381'
          style={{
            backgroundColor: '#F6F7F8',
            padding: 16,
            borderRadius: 8,
          }}
        >
          {fixedIncomePosition?.nickname}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <InformationBlock
          label='Data de aplicação'
          value={formatDateOrUndefined(fixedIncomePosition?.applicationDate)}
          loading={loadingFixedIncomePosition}
        />
      </Grid>
      <Grid item xs={4}>
        <InformationBlock
          label='Data de vencimento'
          value={formatDateOrUndefined(fixedIncomePosition?.maturityDate)}
          loading={loadingFixedIncomePosition}
        />
      </Grid>
      <Grid item xs={4}>
        <InformationBlock
          label='Taxa'
          value={fixedIncomePositionYieldDescription}
          loading={loadingFixedIncomePosition}
        />
      </Grid>
      <Grid item xs={4}>
        <InformationBlock
          label='Duration'
          value={durationString}
          loading={loadingFixedIncomePosition}
        />
      </Grid>

      <Grid item xs={8}>
        <InformationBlock
          label='Quantidade'
          value={fixedIncomePosition?.quantity}
          loading={loadingFixedIncomePosition}
        />
      </Grid>
      <Grid item xs={4}>
        <InformationBlock
          label='Valor aplicado'
          value={formatReaisOrUndefined(fixedIncomePosition?.appliedTotalPrice)}
          loading={loadingFixedIncomePosition}
        />
      </Grid>
      <Grid item xs={4}>
        <InformationBlock
          label='Valor bruto na curva'
          value={formatReaisOrUndefined(fixedIncomePosition?.currentPrice)}
          loading={loadingFixedIncomePosition}
        />
      </Grid>
      <Grid item xs={4}>
        <InformationBlock
          label='Valor líquido na curva'
          // TODO: currentNetPrice on Position

          value={formatReaisOrUndefined(dealFinancials?.currentNetPrice)}
          loading={loadingDealFinancials}
        />
      </Grid>

      <Grid item xs={4}>
        <RebuyUnitPriceInformationBlock
          positionId={positionId}
          offerId={offerId}
        />
      </Grid>
      <Grid item xs={4}>
        <InformationBlock
          label='Valor bruto de recompra'
          value={formatReaisOrUndefined(
            fixedIncomePosition?.ticker?.rebuyTotalPrice,
          )}
          loading={loadingFixedIncomePosition}
        />
      </Grid>
      <Grid item xs={4}>
        <InformationBlock
          label='Valor líquido de recompra'
          value={formatReaisOrUndefined(
            fixedIncomePosition?.ticker?.rebuyTotalNetPrice,
          )}
          loading={loadingFixedIncomePosition}
        />
      </Grid>

      <Grid item xs={4}>
        {offerId && (
          <InformationBlock
            label={
              shouldDisplayProjectedPrice ? (
                <>Liquido projetado em {valueOrLoader(minorDuration)} anos</>
              ) : (
                'Valor líquido projetado'
              )
            }
            value={
              shouldDisplayProjectedPrice
                ? formatReaisOrUndefined(
                    // TODO: Get from Position
                    dealFinancials?.fixedIncomePositionProjectedPrice,
                  )
                : 'Indisponível'
            }
            tooltip={getTooltipYearlyYieldRate(
              fixedIncomePosition?.yieldDescription?.indexer as Indexers,
              // TODO: Get from Position
              dealFinancials?.positionYearlyYieldRate,
            )}
            loading={loadingDealFinancials}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {shouldDisplayCustomerMarkupOnOperationDate && (
          <InformationBlock
            label='Ágio D+0'
            value={customerMarkupOnOperationDateReais}
            valueStyle={{
              color: customerMarkupOnOperationDateColor,
            }}
            loading={loadingDealFinancials}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {shouldDisplayAccumulativeDividends && (
          <InformationBlock
            label='Valor acumulado em proventos'
            value={formatReaisOrUndefined(
              fixedIncomePosition?.accumulatedDividends,
            )}
            loading={loadingDealFinancials}
          />
        )}
      </Grid>
    </Grid>
  );
};
