import { Image } from '@invest-ai/animatrix';
import { Box, Grid, Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useState } from 'react';
import { useCalculateDealFromRoute } from '../../hooks/useCalculateDealFromRoute';
import { DealPitch } from '../DealPitch';
import { LoadingText } from '../InformationBlock/LoadingText';
import { ComparisonChart } from './components/ComparisonChart';
import { DealFinancials } from './components/DealFinancials';
import { DealFixedIncomeOffer } from './components/DealFixedIncomeOffer';
import { DealFixedIncomePosition } from './components/DealFixedIncomePosition';
import { MissingPosition } from './components/MissingPosition';
import type { MaybeNil } from './logic/MaybeNil';
import { getCustomerIdentificationLabel } from './logic/getCustomerIdentificationLabel';

export const DisplayDeal: React.FC<{
  positionId: string;
  offerId: MaybeNil<string>;
  officeId?: MaybeNil<string>;
  isCustomer?: MaybeNil<boolean>;
}> = ({ positionId, offerId, officeId, isCustomer }) => {
  const { fixedIncomePosition, dealFinancials, loading } =
    useCalculateDealFromRoute({
      positionId,
      offerId,
    });

  const [imgSrc, setImgSrc] = useState(
    `https://storage.googleapis.com/invest-ai/corleone/offices/${officeId}.png`,
  );

  // TODO
  const shouldDisplayMissingPositionMessage = false;

  const customerName = fixedIncomePosition?.brokerAccount.customerName;
  const accountNumber = fixedIncomePosition?.brokerAccount.accountNumber;
  const tickerId = fixedIncomePosition?.ticker?.id;
  const shouldDisplayChart = !!(positionId && offerId);
  const shouldDisplayPitch = !!offerId;
  const customerIdentificationLabel = getCustomerIdentificationLabel(
    customerName,
    accountNumber,
    tickerId,
  );

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={2}>
        {officeId && (
          <Grid xs={12} item>
            <Image
              src={imgSrc}
              height={40}
              onError={() => setImgSrc('/static/brand/logo.png')}
            />
          </Grid>
        )}
        {shouldDisplayMissingPositionMessage && (
          <Grid xs={12} item>
            <MissingPosition positionId={positionId} />
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          <Paper elevation={5}>
            <Box paddingX={3} paddingY={2}>
              <Typography
                fontSize={12}
                fontWeight={700}
                textTransform='uppercase'
                color='#637381'
              >
                {loading ? (
                  <LoadingText height={18} />
                ) : (
                  customerIdentificationLabel
                )}
              </Typography>
            </Box>

            <Divider />

            <Box padding={3}>
              <DealFixedIncomePosition
                positionId={positionId}
                offerId={offerId}
              />
              <DealFixedIncomeOffer offerId={offerId} positionId={positionId} />
            </Box>
            <Divider />
            {offerId && (
              <DealFinancials offerId={offerId} positionId={positionId} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          {shouldDisplayChart && (
            // TODO: Return Comparison Chart on DealDashboard query
            <ComparisonChart
              fixedIncomeOfferId={offerId}
              fixedIncomePositionId={positionId}
              offerPriceApplied={
                dealFinancials?.substituteFixedIncomeOfferPriceApplied ?? 0
              }
              // TODO: Get from Position
              positionPriceApplied={dealFinancials?.currentPrice ?? 0}
            />
          )}
          {shouldDisplayPitch && (
            <DealPitch offerId={offerId} positionId={positionId} />
          )}
          {isCustomer && (
            <Typography variant='caption' p={2} display={'inline-block'}>
              * Os preços unitários e taxas citadas são indicativas e podem
              sofrer variações positivas e negativas no momento de fechamento da
              operação, de acordo com condições de mercado.
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};
