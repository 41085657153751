import { useUpdateFixedIncomePosition } from './useUpdateFixedIncomePosition';
import { useUpdateFixedIncomePositionPublic } from './useUpdateFixedIncomePositionPublic';

export const useGetFixedIncomePositionUpdater = (isPublic: boolean) => {
  const {
    loading: loadingUpdateFixedIncomePosition,
    updateFixedIncomePosition,
  } = useUpdateFixedIncomePosition();

  const {
    loading: loadingUpdateFixedIncomePositionPublic,
    updateFixedIncomePositionPublic,
  } = useUpdateFixedIncomePositionPublic();

  return {
    updateFixedIncomePosition: isPublic
      ? updateFixedIncomePositionPublic
      : updateFixedIncomePosition,
    loading: isPublic
      ? loadingUpdateFixedIncomePositionPublic
      : loadingUpdateFixedIncomePosition,
  };
};
