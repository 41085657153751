export const getCustomerDealLink = (
  dealLink: string | undefined,
  officeId: string | undefined,
) => {
  if (!dealLink || !officeId) return '';

  return `${dealLink}?o=${officeId}`.replace(
    /\/p\/negociacao/g,
    '/c/negociacao',
  );
};
