import { defaultCompareOffersColumns } from '../column-definitions/defaultCompareOffersColumns';

export const getCompareOffersColumnsToRender = (
  financialInstitutionId: string | undefined,
) => {
  if (!financialInstitutionId) return defaultCompareOffersColumns;

  if (financialInstitutionId === 'BTG')
    return [
      ...defaultCompareOffersColumns,
      {
        field: 'externalId',
        headerName: 'ID',
        hideable: false,
        disableExport: false,
        filterable: false,
      },
    ];

  return defaultCompareOffersColumns;
};
