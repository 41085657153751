import { EnhancedDataGrid } from '@common/components/EnhancedDataGrid/EnhancedDataGrid';
import { formatYieldDescription } from '@common/logic/formatYieldDescription';
import { Box, LinearProgress } from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid-premium';
import React, { useMemo, useState } from 'react';
import { FixedIncomeOfferFragmentFragment } from '../../../../../../../__generated__/types';
import { getAmortizationTextOrUndefined } from '../../../../../../deals/components/DisplayDeal/logic/getAmortizationText';
import { CustomToolbar } from './CustomToolbar';
import { DEFAULT_SORT_MODEL } from './DEFAULT_SORT_MODEL';
import { NoOffersMessage } from './NoOffersMessage';
import { PAGINATION_MODEL_INITIAL_STATE } from './PAGINATION_MODEL_INITIAL_STATE';
import { getColumnsToRender } from './getColumnsToRender';
import { localStorageSortKey } from './localStorageSortKey';

interface IFixedIncomeOffersListProps {
  fixedIncomeOffers: Array<FixedIncomeOfferFragmentFragment>;
  onSelectFixedIncomeOffer: (fixedIncomeOfferId: string) => void;
  onClickCreateOffer: () => void;
  onClickEditOffer: (offerId: string) => void;
  loading: boolean;
}

export const FixedIncomeOffersList: React.FC<IFixedIncomeOffersListProps> = ({
  fixedIncomeOffers,
  onSelectFixedIncomeOffer,
  onClickCreateOffer,
  onClickEditOffer,
  loading,
}) => {
  const [rowSelectionModel] = React.useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState(
    PAGINATION_MODEL_INITIAL_STATE,
  );
  const sortFromStorage = localStorage.getItem(localStorageSortKey);

  const [sortModel, setSortModel] = React.useState<GridSortModel>(
    sortFromStorage ? JSON.parse(sortFromStorage) : DEFAULT_SORT_MODEL,
  );

  function onSortModelChange(newSortModel: GridSortModel) {
    setSortModel(newSortModel);
    localStorage.setItem(localStorageSortKey, JSON.stringify(newSortModel));
  }

  const rows = useMemo(
    () =>
      // TODO: Move to column formatting
      fixedIncomeOffers.map((fixedIncomeOffer) => {
        return {
          ...fixedIncomeOffer,
          yieldRate: formatYieldDescription(fixedIncomeOffer.yieldDescription),
          firstAmortizationPaymentDate: getAmortizationTextOrUndefined(
            fixedIncomeOffer?.amortizationPeriod,
            fixedIncomeOffer?.firstAmortizationPaymentDate,
          ),
          customerMarkUp: fixedIncomeOffer.financials?.customerMarkUp,
          stretchingInMonths: fixedIncomeOffer.financials?.stretchingInMonths,
        };
      }),
    [fixedIncomeOffers],
  );

  const columnsToRender = useMemo(
    () => getColumnsToRender(onClickEditOffer),
    [onClickEditOffer],
  );
  return (
    <Box sx={{ height: 635 }}>
      <EnhancedDataGrid
        loading={loading}
        rows={rows}
        columns={columnsToRender}
        autoHeight={false}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowSelectionModel={rowSelectionModel}
        hideFooterSelectedRowCount
        onRowClick={(row) => onSelectFixedIncomeOffer(row.id as string)}
        sx={{
          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
        slots={{
          toolbar: () => (
            <CustomToolbar onClickCreateOffer={onClickCreateOffer} />
          ),
          loadingOverlay: LinearProgress,
          noRowsOverlay: () => <NoOffersMessage />,
        }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
      />
    </Box>
  );
};
