import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { MaybeNil } from '../../../deals/components/DisplayDeal/logic/MaybeNil';
import { useRouteQuery } from '../../../deals/pages/DealsPage/useRouteQuery';
import { useComparadorOfferDrawersNavigation } from '../../../offers/context/useComparadorOfferDrawersNavigation';
import { useFixedIncomeOfferById } from '../../../offers/hooks/useFixedIncomeOfferById';
import { FakeSelectOffer } from '../FakeSelectOffer';
import { FixedIncomePositionsList } from '../FixedIncomePositionsList';
import { getCompareOffersColumnsToRender } from '../FixedIncomePositionsList/logic/getCompareOffersColumnsToRender';

export const CompareOffersDashboard: React.FC = () => {
  const query = useRouteQuery();
  const selectedOfferId: MaybeNil<string> = query.get('offer');
  const location = useLocation();
  const navigate = useNavigate();

  const cleanSelectedOffer = () => {
    query.delete('offer');
    navigate({
      pathname: location.pathname,
      hash: location.hash,
      search: query.toString(),
    });
  };
  const { openAvailableOffersDrawer } = useComparadorOfferDrawersNavigation();

  const { fixedIncomeOffer } = useFixedIncomeOfferById(selectedOfferId, {
    skip: !selectedOfferId,
  });

  const offerSelectedLabel = fixedIncomeOffer?.nickname;

  const compareOffersColumns = getCompareOffersColumnsToRender(
    fixedIncomeOffer?.financialInstitutionId,
  );

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <Paper elevation={5} style={{ padding: 24 }}>
          <Box justifyContent='space-between' display='flex'>
            <FakeSelectOffer
              openDrawer={openAvailableOffersDrawer}
              offerSelectedLabel={offerSelectedLabel}
              freeOfIR={fixedIncomeOffer?.freeOfIR}
              investorQualified={fixedIncomeOffer?.target === 'QUALIFIED'}
              yieldDescription={fixedIncomeOffer?.yieldDescription}
              roa={fixedIncomeOffer?.roa}
              duration={fixedIncomeOffer?.duration}
            />
            <Button
              onClick={cleanSelectedOffer}
              sx={{ ml: 2 }}
              disabled={!selectedOfferId}
            >
              Limpar
            </Button>
          </Box>
        </Paper>
        {fixedIncomeOffer && (
          <Paper elevation={5} sx={{ marginTop: 2 }}>
            <FixedIncomePositionsList
              baseUrl='/comparador/'
              columnsToRender={compareOffersColumns}
              financialsParams={{
                offerId: fixedIncomeOffer.id,
                quantity: 1,
              }}
              // TODO: Refactor this
              offerMaturityDate={fixedIncomeOffer.maturityDate}
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
