import { Box, BoxProps, Typography } from '@mui/material';

export const NoOffersMessage: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      height={'100%'}
      p={8}
      {...props}
    >
      <Box>
        <Typography variant='body1' gutterBottom>
          A importação desse tipo de oferta não é feita de forma automática,
          pois dificilmente a troca seria vantajosa para o cliente.
        </Typography>
        <Typography variant='body1'>
          De qualquer forma, você ainda pode clicar em "CRIAR OFERTA" para
          cadastrar o ativo que deseja trabalhar, é super simples e rápido!
        </Typography>
      </Box>
    </Box>
  );
};
