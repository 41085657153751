import { Loading } from '@invest-ai/animatrix';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { useRenamePositionsImporterHistoric } from '../../hooks/useRenamePositionsImporterHistoric';

export const UpdateImportedItemName: React.FC<{
  itemId: string;
  currentName: string;
}> = ({ itemId, currentName }) => {
  const { loading, renamePositionsImporterHistoric } =
    useRenamePositionsImporterHistoric();

  const handleUpdate = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    try {
      event.stopPropagation();
      event.preventDefault();

      const name = prompt('Alterar nome da importação', currentName ?? '');
      if (!name) return;

      await renamePositionsImporterHistoric(itemId, name);
      toast.success('Nome atualizado com sucesso!');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  return (
    <IconButton onClick={handleUpdate} sx={{ p: 1 }}>
      {!loading ? (
        <Edit sx={{ width: 14, height: 14 }} />
      ) : (
        <Loading size={14} />
      )}
    </IconButton>
  );
};
