import { formatReaisOrUndefined } from '@common/logic/formatReaisOrUndefined';
import { CustomPopover, usePopover } from '@invest-ai/animatrix';
import { Box, Typography } from '@mui/material';
import { InformationBlock } from '../../InformationBlock';
import { useDealFixedIncomePositionComponentData } from '../hooks/useComponentData';
import { MaybeNil } from '../logic/MaybeNil';
import { TickerDataSourceBlock } from './TickerDataSourceBlock';
import { UpdateRebuyUnitPrice } from './UpdateRebuyUnitPrice';

const getChoosenAndNotChoosenTickerDataSources = (
  tickerDataSources: Array<any>,
) => {
  return {
    notChoosenTickerDataSources: tickerDataSources.filter((t) => !t.isChoosen),
    choosenTickerDataSource: tickerDataSources.find((t) => t.isChoosen),
  };
};

export const RebuyUnitPriceInformationBlock: React.FC<{
  positionId: string;
  offerId: MaybeNil<string>;
}> = ({ positionId, offerId }) => {
  const popover = usePopover();

  const {
    shouldDisplayUpdateRebuyUnitPrice,
    loadingFixedIncomePosition,
    fixedIncomePosition,
    refetchDealFromRoute,
  } = useDealFixedIncomePositionComponentData({ positionId, offerId });

  const rebuyUnitPriceSources =
    fixedIncomePosition?.ticker?.rebuyUnitPriceSources ?? [];

  const { choosenTickerDataSource, notChoosenTickerDataSources } =
    getChoosenAndNotChoosenTickerDataSources(rebuyUnitPriceSources);

  return (
    <>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ p: 2 }}
      >
        {choosenTickerDataSource && (
          <>
            <Typography variant='caption'>PU Considerado</Typography>
            <Box>
              <TickerDataSourceBlock
                key={choosenTickerDataSource.source}
                tickerDataSource={choosenTickerDataSource}
              />
            </Box>
          </>
        )}

        <Typography variant='caption'>Outras fontes</Typography>
        <Box>
          {notChoosenTickerDataSources.map((tickerDataSource) => (
            <TickerDataSourceBlock
              key={tickerDataSource.source}
              tickerDataSource={tickerDataSource}
            />
          ))}
        </Box>
      </CustomPopover>

      <InformationBlock
        label='PU recompra'
        value={
          <Typography
            component='span'
            onClick={popover.onOpen}
            style={{
              cursor: 'pointer',
              textDecoration: 'underline dotted',
            }}
          >
            {formatReaisOrUndefined(
              fixedIncomePosition?.ticker?.rebuyUnitPrice,
            ) ?? 'Indisponível'}
          </Typography>
        }
        loading={loadingFixedIncomePosition}
        addornment={
          shouldDisplayUpdateRebuyUnitPrice && (
            <UpdateRebuyUnitPrice
              positionId={positionId}
              onSuccess={async () => {
                await refetchDealFromRoute();
              }}
              currentRebuyUnitPrice={
                fixedIncomePosition?.ticker?.rebuyUnitPrice ?? 0
              }
            />
          )
        }
      />
    </>
  );
};
