import { gql, useMutation } from '@apollo/client';
import type {
  UpdateFixedIncomePositionInput,
  UpdateFixedIncomePositionPublicMutation,
  UpdateFixedIncomePositionPublicMutationVariables,
} from '../../../__generated__/types';

export const UPDATE_FIXED_INCOME_POSITION_PUBLIC_MUTATION = gql`
  mutation UpdateFixedIncomePositionPublic(
    $id: UUID!
    $input: UpdateFixedIncomePositionInput!
  ) {
    updateFixedIncomePositionPublic(id: $id, input: $input) {
      id
      createdBy
      rebuyUnitPrice
    }
  }
`;

export const useUpdateFixedIncomePositionPublic = () => {
  const [_updateFixedIncomePositionPublic, { data, loading }] = useMutation<
    UpdateFixedIncomePositionPublicMutation,
    UpdateFixedIncomePositionPublicMutationVariables
  >(UPDATE_FIXED_INCOME_POSITION_PUBLIC_MUTATION);

  const updateFixedIncomePositionPublic = (
    id: string,
    input: UpdateFixedIncomePositionInput,
  ) =>
    _updateFixedIncomePositionPublic({
      variables: {
        id,
        input,
      },
    });

  return {
    updateFixedIncomePositionPublic,
    data,
    loading,
  };
};
