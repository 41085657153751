import { EnhancedDataGrid } from '@common/components/EnhancedDataGrid/EnhancedDataGrid';
import { DisplayError } from '@invest-ai/animatrix';
import { Box } from '@mui/material';
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { useCallback, useMemo } from 'react';
import { useAdvisoryOfficeId } from 'src/modules/deals/hooks/useAdvisoryOfficeId';
import { useShouldDisplayCustomerName } from '../../../advisor-authentication/hooks/useShouldDisplayCustomerName';
import { usePositionDrawer } from '../../../custom-position/context/usePositionDrawer';
import { FixedIncomePositionsFilterDrawer } from '../FixedIncomePositionsFilterDrawer';
import { FixedIncomePositionsFilterDrawerContext } from '../FixedIncomePositionsFilterDrawer/FixedIncomePositionsFilterDrawerContext';
import { CustomToolbar } from './components/CustomToolbar';
import { COLUMN_VISIBILITY_INITIAL_STATE } from './constants/COLUMN_VISIBILITY_INITIAL_STATE';
import { FixedIncomePositionsListParamsContext } from './hooks/useFixedIncomePositionsListParams';
import { useFixedIncomePositionsToDeal } from './hooks/useFixedIncomePositionsToDeal';
import { IFixedIncomePositionRow } from './interfaces/IFixedIncomePositionRow';
import { getFixedIncomePositionFragmentToFixedIncomePositionRowMapper } from './logic/fixedIncomePositionFragmentToFixedIncomePositionRow';
import { mapGridFilterModelToSearch } from './logic/mapGridFilterModelToSearch';
import { mapGridSortItemToSortParams } from './logic/mapGridSortItemToSortParams';
import { mapPaginationModelToPaginationParams } from './logic/mapPaginationModelToPaginationParams';
import { mapPaginationParamsToPaginationModel } from './logic/mapPaginationParamsToPaginationModel';
import { mapSearchToFilterModel } from './logic/mapSearchToFilterModel';
import { mapSortParamsToSortModel } from './logic/mapSortParamsToSortModel';

interface IFixedIncomePositionsListProps {
  financialsParams?: {
    quantity: number;
    offerId?: string;
  };
  // TODO: Remove this
  offerMaturityDate?: Date;
  columnsToRender: GridColDef<IFixedIncomePositionRow>[];
  baseUrl: string;
}

export const FixedIncomePositionsList: React.FC<
  IFixedIncomePositionsListProps
> = ({ financialsParams, offerMaturityDate, columnsToRender, baseUrl }) => {
  const {
    search,
    filterParams,
    paginationParams,
    sortParams,
    setSortParams,
    setPaginationParams,
    setSearch,
  } = FixedIncomePositionsListParamsContext.useContainer();
  const { openCreatePositionDrawer } = usePositionDrawer(baseUrl);
  const {
    fixedIncomePositions,
    totalRows,
    loading: loadingFixedIncomePositionsToDeal,
    error,
  } = useFixedIncomePositionsToDeal(
    search,
    paginationParams,
    sortParams,
    filterParams,
    financialsParams?.offerId,
    financialsParams?.quantity ?? 1,
  );
  const { officeId } = useAdvisoryOfficeId();

  const {
    shouldDisplayCustomerName,
    loading: loadingShouldDisplayCustomerName,
  } = useShouldDisplayCustomerName();

  const handlePaginationModelChange = (
    gridPaginationModel: GridPaginationModel,
  ) => {
    if (totalRows) {
      const paginationParams =
        mapPaginationModelToPaginationParams(gridPaginationModel);
      setPaginationParams(paginationParams);
    }
  };

  const handleSortModelChange = (gridSortModel: GridSortModel) => {
    const [gridSortItem] = gridSortModel;
    const newSortParams = mapGridSortItemToSortParams(gridSortItem);
    setSortParams(newSortParams);
  };

  const handleFilterModelChange = useCallback(
    (gridFilterModel: GridFilterModel) => {
      const search = mapGridFilterModelToSearch(gridFilterModel);
      setSearch(search);
    },
    [setSearch],
  );

  const columnsToHide = {
    brokerAccountCustomerName: shouldDisplayCustomerName,
  };

  const rows = useMemo(
    () =>
      fixedIncomePositions?.map(
        getFixedIncomePositionFragmentToFixedIncomePositionRowMapper(
          offerMaturityDate ?? new Date(),
          financialsParams?.offerId,
          officeId,
        ),
      ) ?? [],
    [fixedIncomePositions, financialsParams?.offerId, offerMaturityDate],
  );

  if (error) {
    return <DisplayError error={error} />;
  }

  const isLoading =
    loadingFixedIncomePositionsToDeal || loadingShouldDisplayCustomerName;

  const sortModel = mapSortParamsToSortModel(sortParams);
  const paginationModel =
    mapPaginationParamsToPaginationModel(paginationParams);
  const filterModel = mapSearchToFilterModel(search);

  return (
    <FixedIncomePositionsFilterDrawerContext.Provider>
      <Box height={600}>
        <EnhancedDataGrid
          autoHeight={false}
          density='compact'
          rowCount={totalRows ?? 0}
          loading={isLoading}
          rows={rows}
          columns={columnsToRender}
          columnsToHide={columnsToHide}
          initialState={{
            columns: {
              columnVisibilityModel: COLUMN_VISIBILITY_INITIAL_STATE,
            },
          }}
          // filter
          filterMode='server'
          filterModel={filterModel}
          // sorting
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          sortModel={sortModel}
          // pagination
          paginationMode='server'
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          slots={{
            toolbar: () => (
              <CustomToolbar
                initialSearch={search}
                setFilterModel={handleFilterModelChange}
                onPressCreatePosition={openCreatePositionDrawer}
              />
            ),
          }}
        />
      </Box>
      <FixedIncomePositionsFilterDrawer />
    </FixedIncomePositionsFilterDrawerContext.Provider>
  );
};
