import { gql, useMutation } from '@apollo/client';
import type {
  RenamePositionsImporterHistoricMutation,
  RenamePositionsImporterHistoricMutationVariables,
} from 'src/__generated__/types';
import { POSITIONS_IMPORTER_HISTORIC_QUERY } from './useMyPositionsImporterHistoric';

const RENAME_POSITIONS_IMPORTER_HISTORIC_MUTATION = gql`
  mutation RenamePositionsImporterHistoric($input: RenamePositionsImporterHistoricInput!) {
    renamePositionsImporterHistoric(input: $input) {
      id
      date
      referenceDate
      name
      parsingErrors {
        line
        columnName
        value
        reason
      }
      successCount
      parsingIgnored {
        line
        columnName
        value
        reason
      }
    }
  }
`;

export const useRenamePositionsImporterHistoric = () => {
  const [_renamePositionsImporterHistoric, { data, error, loading }] =
    useMutation<
      RenamePositionsImporterHistoricMutation,
      RenamePositionsImporterHistoricMutationVariables
    >(RENAME_POSITIONS_IMPORTER_HISTORIC_MUTATION);

  const renamePositionsImporterHistoric = (id: string, name: string) =>
    _renamePositionsImporterHistoric({
      variables: {
        input: { id, name },
      },
      refetchQueries: [POSITIONS_IMPORTER_HISTORIC_QUERY],
    });

  return {
    renamePositionsImporterHistoric,
    data,
    error,
    loading,
  };
};
